import { Informacion_Galeria } from "../../utils/Constant"
import React from "react";
import Carousel from "./Slider";
import "./Galeria.scss";
import {
  Columna,
  Columnas,
  Title,
  Roboto,
  Line,
} from "../../Components/Styled2";


const Galeria = ({ galeria }) => {
  const { Titulo, SubTitulo, Imgs } = Informacion_Galeria;
  return (
    <section ref={galeria} className="Galeria-Container">
      <Columnas
        varwidth="87.4%"
        vargridcolumns="44% 65%"
        varalignitems="center"
        vardirection="column"
        className="gridgapSlider"
      >
        <Columna varmarginmovil="auto" varwidthmovil="84%">
          <Line />
          <Title
            fontsize="42px"
            varpadd="10px 0 0"
            varpaddi="10px 0 0"
            varalignt="left"
          >
            {Titulo}
          </Title>
          <Roboto fontsizem="16px">
            {SubTitulo}
          </Roboto>
        </Columna>
        <Columna>
          <Carousel images={Imgs} />
        </Columna>
      </Columnas>
    </section>
  );
};

export default Galeria;
