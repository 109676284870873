import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Pages/Home";
import PageWrapper from "./Sections/PageWrapper";
import { useState, useRef, useEffect } from "react";
import { Informacion_Header } from './utils/Constant';
import moment from "moment";
import WhatsappButton from "./Components/WhatsappButton";

function App() {
  const { Headerday, Headernight } = Informacion_Header;

  // Inicio del boton
  const [btnState, setBtnState] = useState(true);
  //Cambio de background según la hora
  const morningImage = Headerday;
  const afternoonImage = Headerday;
  const nightImage = Headernight;

  useEffect(() => {
    // Función para actualizar el fondo
    const updateBackground = () => {
      const now = moment(); // Obtener la hora actual
      const morningTime = moment("06:00:00", "HH:mm:ss"); // 6:00 a.m.
      const afternoonTime = moment("12:00:00", "HH:mm:ss"); // 12:00 p.m.
      const nightTime = moment("18:00:00", "HH:mm:ss"); // 6:00 p.m.

      if (now.isBefore(morningTime)) {
        setBackgroundImage(nightImage);
        setBtnState(false);
      } else if (now.isBefore(afternoonTime)) {
        setBackgroundImage(morningImage);
        setBtnState(true);
      } else if (now.isBefore(nightTime)) {
        setBackgroundImage(afternoonImage);
        setBtnState(true);
      } else {
        setBackgroundImage(nightImage);
        setBtnState(false);
      }
    };

    // Establecer el fondo al cargar la página
    updateBackground();
    // Programar la actualización del fondo cada minuto
    const intervalId = setInterval(updateBackground, 15 * 60 * 1000);
    // Limpiar el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, []);
  //fin del cambio de background según la hora

  // Cambio de background en Header
  const [backgroundImage, setBackgroundImage] = useState(btnState ? Headerday : Headernight);
  const [isFirstImage, setIsFirstImage] = useState(btnState);

  const handleClick = () => {
    setIsFirstImage(!isFirstImage);
  };

  useEffect(() => {
    if (isFirstImage) {
      setBackgroundImage(Headerday);
    } else {
      setBackgroundImage(Headernight);
    }
  }, [Headerday, Headernight, isFirstImage]);

  useEffect(() => {
    setIsFirstImage(btnState);
  }, [btnState]);

  //Fin de cambio en Background de Home

  //Enlace a Galeria del sitio web
  const galeria = useRef(null);
  const executeScroll = () => {
    window.scrollTo({
      top: galeria.current.offsetTop - 65,
      behavior: "smooth",
    });
  };
  //Fin de enlaces a Galeria del sitio web
  //Enlace a Ubicacion del sitio web
  const ubicacion = useRef(null);
  const executeScrollubicacion = () => {
    window.scrollTo({
      top: ubicacion.current.offsetTop - 65,
      behavior: "smooth",
    });
  };
  //Fin de enlaces a Ubicacion del sitio web
  //Enlace a Amenidades del sitio web
  const amenidades = useRef(null);
  const executeScrollamenidades = () => {
    window.scrollTo({
      top: amenidades.current.offsetTop - 65,
      behavior: "smooth",
    });
  };
  //Fin de enlaces a Amenidades del sitio web
  //Enlace a Infraestructura del sitio web
  const infraestructura = useRef(null);
  const executeScrollinfraestructura = () => {
    window.scrollTo({
      top: infraestructura.current.offsetTop - 65,
      behavior: "smooth",
    });
  };
  //Fin de enlaces a infraestructura del sitio web

  //Enlace a Comercializador del sitio web
  const propiedades = useRef(null);
  const executeScrollpropiedades = () => {
    window.scrollTo({
      top: propiedades.current.offsetTop - 65,
      behavior: "smooth",
    });
  };
  //Fin de enlaces a comercializador del sitio web

  const disponibilidad = useRef(null);
  const executeScrolldisponibilidad = () => {
    window.scrollTo({
      top: disponibilidad.current.offsetTop - 65,
      behavior: "smooth",
    });
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="Construccion"
          element={<PageWrapper>Construccion</PageWrapper>}
        />
        <Route
          path="/"
          element={
            <PageWrapper
              backgroundImage={backgroundImage}
              btnState={btnState}
              handleClick={handleClick}
              executeScroll={executeScroll}
              executeScrollubicacion={executeScrollubicacion}
              executeScrollamenidades={executeScrollamenidades}
              executeScrollinfraestructura={executeScrollinfraestructura}
              executeScrollpropiedades={executeScrollpropiedades}
              executeScrolldisponibilidad={executeScrolldisponibilidad}
            >
              <Home
                backgroundImage={backgroundImage}
                handleClick={handleClick}
                galeria={galeria}
                ubicacion={ubicacion}
                amenidades={amenidades}
                infraestructura={infraestructura}
                propiedades={propiedades}
                disponibilidad={disponibilidad}
              />

              <WhatsappButton />
            </PageWrapper>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
