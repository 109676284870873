/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./SwitchButton.scss";

function SwitchButton({ btnState }) {
  const [isActive, setIsActive] = useState(btnState);
  const handleToggle = () => {
    setIsActive(!isActive);
  };

  useEffect(() => {
    if (isActive !== btnState) {
      setIsActive(btnState);
    }
  }, [btnState]);

  return (
    <button
      className={`switch-button ${!isActive ? "active" : ""}`}
      onClick={handleToggle}
    >
      <div className="switch-background"></div>
      <div className="switch-knob"></div>
    </button>
  );
}

export default SwitchButton;
