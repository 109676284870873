// Imagenes
// Header
import Headerday from "../../assets/img/CIUDAD_ENCANTADA_HOME_HEADER_DIA.png";
import Headernight from "../../assets/img/CIUDAD_ENCANTADA_HOME_HEADER_NOCHE.png";
import Caracteristica1_Img from "../../assets/img/CIUDAD_ENCANTADA_HOME_HEADER_TERRENOS_RESIDENCIALES.png";
import Caracteristica2_Img from "../../assets/img/CIUDAD_ENCANTADA_HOME_HEADER_UBICACION.png";
import Caracteristica3_Img from "../../assets/img/CIUDAD_ENCANTADA_HOME_HEADER_CICLOVIA.png";
import Caracteristica4_Img from "../../assets/img/CIUDAD_ENCANTADA_HOME_HEADER_PLAZACENTRAL.png";
// Logos
import Logo from "../../assets/img/CIUDAD_ENCANTADA_LOGO.png";
import LogoAPI from '../../assets/img/CIUDAD_ENCANTADA_LogoAPI.png';
import LogoWhatsapp from "../../assets/img/CiudadLaEncantada_whatsapp.png";

// Galeria 
import Galeria_1 from "../../assets/img/CIUDAD_ENCANTADA_GALERIA_LAGUNA_INDUSTRIAL_PARK.png";
import Galeria_2 from "../../assets/img/CIUDAD_ENCANTADA_GALERIA_LAGUNA_INDUSTRIAL_PARK1.png";
import Galeria_3 from "../../assets/img/CIUDAD_ENCANTADA_GALERIA_LAGUNA_INDUSTRIAL_PARK2.png";
import Galeria_4 from "../../assets/img/CIUDAD_ENCANTADA_GALERIA_ciudad la encantada.png";
import Galeria_5 from "../../assets/img/CIUDAD_ENCANTADA_GALERIA_PLAZA CENTRAL FINAL 4_3 - Photo.png";
import Galeria_6 from "../../assets/img/CIUDAD_ENCANTADA_GALERIA_F20_6 - Foto.png";
import Galeria_7 from "../../assets/img/CIUDAD_ENCANTADA_GALERIA_PLAZA CENTRAL FINAL 4_6 - Photo.png";
import Galeria_8 from "../../assets/img/CIUDAD_ENCANTADA_GALERIA_Imagen3.png";

// Infraestructura
import camara from "../../assets/img/CIUDAD_ENCANTADA_INFRAESTRUCTURA_icon-cam.png";
import drenajePluvial from "../../assets/img/CIUDAD_ENCANTADA_INFRAESTRUCTURA_agua.png";
import oficina from "../../assets/img/CIUDAD_ENCANTADA_INFRAESTRUCTURA_workspace.png";
import agua from "../../assets/img/CIUDAD_ENCANTADA_INFRAESTRUCTURA_icon-agua.png";
import img1Infraestructura from "../../assets/img/CIUDAD_ENCANTADA_INFRAESTRUCTURA_RECTANGLE 856.png";
import img2Infraestructura from "../../assets/img/CIUDAD_ENCANTADA_INFRAESTRUCTURA_RECTANGLE 857.png";
import bandera from "../../assets/img/CiudadLaEncantada_INFRAESTRUCTURA_BANDERITA.png";
import Render1 from "../../assets/img/CIUDAD_ENCANTADA_TAMANOS_RENDER1.png";
import Render2 from "../../assets/img/CIUDAD_ENCANTADA_TAMANOS_RENDER2.png";
import Render3 from "../../assets/img/CIUDAD_ENCANTADA_TAMANOS_RENDER3.png";

// Presentacion 
import DispositivosMoviles from "../../assets/img/CIUDAD_ENCANTADA_PRESENTACION_MACBOOK_SMARTFHONE.png";

// Propiedades mexico
import propiedades_mexico from "../../assets/img/CIUDAD_ENCANTADA_PROPIEDADES_MEXICO_pm-logo.png";
import imgDiv2 from "../../assets/img/CIUDAD_ENCANTADA_PROPIEDADES_MEXICO_Rectangle 863.png";
import icon1 from "../../assets/img/CIUDAD_ENCANTADA_PROPIEDADES_MEXICO_ICONO1.png";
import icon2 from "../../assets/img/CIUDAD_ENCANTADA_PROPIEDADES_MEXICO_ICONO2.png";
import icon3 from "../../assets/img/CIUDAD_ENCANTADA_PROPIEDADES_MEXICO_ICONO3.png";
import icon4 from "../../assets/img/CIUDAD_ENCANTADA_PROPIEDADES_MEXICO_ICONO4.png";
import icon5 from "../../assets/img/CIUDAD_ENCANTADA_PROPIEDADES_MEXICO_ICONO5.png";

// Resultados
import Propiedades from "../../assets/img/CIUDAD_ENCANTADA_RESULTADOS_PROPIEDADES_VERDES.png";
import Asesores from "../../assets/img/CIUDAD_ENCANTADA_RESULTADOS_ASESORES.png";
import Creditos from "../../assets/img/CIUDAD_ENCANTADA_RESULTADOS_CREDITOS_GENERALES.png";
import PropiedadesenLista from "../../assets/img/CIUDAD_ENCANTADA_RESULTADOS_PROPIEDADES_EN_LISTA.png";

// HEX=#b3282d
const Colores_Pagina = {
    color_principa: "#b3282d",
    // gradient
    color_resultados: "#b3282d, #842023, #842023",
}

// Header
const Informacion_Header = {
    // Imagenes de fondo 
    Headerday: Headerday,
    Headernight: Headernight,
    //
    Titulo: "CIUDAD LA ENCANTADA",
    SubTitulo: "Vive en un entorno de armonía y seguridad, con una ubicación privilegiada en Gómez Palacio. Amenidades, tecnología e infraestructura de vanguardia.",
    Logo: { href: "https://ciudadlaencantada.com", img: Logo, alt: "CIUDAD LA ENCANTADA" },
    Telefono: "5218711377291",
    Info: "CIUDAD LA ENCANTADA",
    Caracteristicas: [ // Maximo 20 caracteristicas
        { id: 1, alt: "terrenos residenciales", Titulo: "Terrenos residenciales", Valor: "250 M2, 200 M2, 160 M2", Img: Caracteristica1_Img },
        { id: 2, alt: "ubicación", Titulo: "Ubicación", Valor: "En la Laguna", Img: Caracteristica2_Img },
        { id: 3, alt: "ciclovía", Titulo: "Ciclovía", Valor: "De 3,500 m la más grande de la región.", Img: Caracteristica3_Img },
        { id: 4, alt: "plaza central", Titulo: "Plaza central", Valor: "Cine al aire libre, restaurantes y servicios.", Img: Caracteristica4_Img },
    ],
}
// Footer
const Informacion_Footer = {
    Logo: { href: "https://ciudadlaencantada.com", img: Logo, alt: "CIUDAD LA ENCANTADA" },
    Redes_Sociales: [
        { // HEX=#b3282d
            id: "instagram", href: "https://www.instagram.com/ciudadlaencantada/?hl=es", svg: `
            <svg
            xmlns="http://www.w3.org/2000/svg" 
            width="25" 
            height="25" 
            fill="currentColor" 
            class="bi bi-instagram" 
            viewBox="0 0 16 16"> 
            <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" fill="${Colores_Pagina.color_principa}"/> 
            </svg>`
        },
        {
            id: "facebook", href: "https://www.facebook.com/CiudadLaEncantada/", svg: `
            <svg
            xmlns="http://www.w3.org/2000/svg" 
            width="25" 
            height="25" 
            fill="currentColor" 
            class="bi bi-facebook" 
            viewBox="0 0 16 16"> 
            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" fill="${Colores_Pagina.color_principa}"></path> 
            </svg>
            `
        },
    ]
}
// Formulario de contacto
const Informacion_FormularioContacto = {
    Titulo: "Estámos esperando tu mensaje.",
    Info: "la Ciudad La Encantada",
    Descripcion: "Dejanos tus datos, pronto nuestros asesores se pondrán en contacto contigo",
    Telefono: "5218711377291",
}
// Boton Whatsapp
const Informacion_WhatsappButton = {
    Logo: { id: "Whatsappcontact", alt: "whatsapp contact", img: LogoWhatsapp },
    Telefono: "5218711377291",
    Info: "la Ciudad La Encantada",
}
// Galeria 
const Informacion_Galeria = {
    Titulo: "Galería del proyecto",
    SubTitulo: "Conoce de cerca nuestro proyecto, y no dudes en contactarnos para más información.",

    Imgs: [
        {
            id: 1,
            alt: "Imagen 1",
            url: Galeria_1,
        },
        {
            id: 2,
            alt: "Imagen 2",
            url: Galeria_2,
        },
        {
            id: 3,
            alt: "Imagen 3",
            url: Galeria_3,
        },
        {
            id: 4,
            alt: "Imagen 4",
            url: Galeria_4,
        },
        {
            id: 5,
            alt: "Imagen 5",
            url: Galeria_5,
        },
        {
            id: 6,
            alt: "Imagen 6",
            url: Galeria_6,
        },
        {
            id: 7,
            alt: "Imagen 7",
            url: Galeria_7,
        },
        {
            id: 8,
            alt: "Imagen 8",
            url: Galeria_8,
        },
    ],
}
// Ubicacion 
const Informacion_Ubicacion = {
    SubTitulo: "Te brindamos una ubicación premium justo en el corazón de la Laguna, frente al Periférico que es el Blvd central.",
    UrlMapa: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3598.95854277827!2d-103.469388!3d25.573046!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x868fd97b63aabf37%3A0x2763e444ba8d123a!2sCiudad%20La%20Encantada!5e0!3m2!1ses!2smx!4v1686756760002!5m2!1ses!2smx",
    Valores: [
        { id: 1, texto: "A 1 min de Walmart Gómez Palacio" },
        { id: 2, texto: "A 5 min de Torreón" },
        { id: 3, texto: "A 5 min del Campestre de Gómez" },
        { id: 4, texto: "A 10 min de Lerdo" },
        { id: 5, texto: "A 5 min de Galerías" },
        { id: 6, texto: "A 7 min de Paseo Gómez Palacio" },
    ],
}
// Amenidades
const Informacion_Amenidades = {
    SubTitulo: "Pensamos lo mejor para tú día a día y como elevar la calidad de vida.",
}
// Infraestructura
const Informacion_Infraestructura = {
    SubTitulo: "En Ciudad La Encantada tendremos estos grandes beneficios.",
    Beneficios: [
        { id: 1, alt: "sistema vigilancia", titulo: "Sistema de videovigilancia", subTitulo: "Sistema de videovigilancia en todas las vialidades principales del desarrollo. Para lograr ser una de las zonas más seguras de toda la Comarca Lagunera. ", img: camara },
        { id: 2, alt: "drenaje pluvial", titulo: "Drenaje pluvial", subTitulo: "Drenaje pluvial en todo el desarrollo para evitar los encharcamientos en épocas de lluvias.", img: drenajePluvial },
        { id: 3, alt: "oficina central", titulo: "Oficina de administración central", subTitulo: " Administración general de las cuotas de mantenimiento del desarrollo para asegurar el excelente mantenimiento del desarrollo.", img: oficina },
        { id: 4, alt: "tratamiento aguas", titulo: "Planta de tratamiento de aguas", subTitulo: " Planta de tratamiento para asegurar el agua requerida para las áreas verdes de manera autosustentable.", img: agua },
    ],
    Imagenes: [
        { id: 5, alt: "edificio", nombre: "ciudad encantada-infraestructura edificio", class: "", imagen: img1Infraestructura },
        { id: 6, alt: "estructura edifcio", nombre: "ciudad encantada-infraestructura estructura edifcio", class: "infraimagen", imagen: img2Infraestructura },
        { id: 7, alt: "banderita", nombre: "ciudad encantada-infraestructura banderita roja", class: "banderitaRoja", imagen: bandera },
    ],
}
// Disponibilidad
const Informacion_Disponibilidad = {
    Enganche: "$3,500 pesos ",
    Datos: [             // Lotes /   Tamaños /      Desde
        { id: "uno", lote: "160 m2", tamano: "8x20", precio: "$168,000 MXN" },
        { id: "dos", lote: "200 m2", tamano: "10x20", precio: "$210,000 MXN" },
        { id: "tres", lote: "250 m2", tamano: "12.5x20", precio: "$262,500 MXN" },
    ],
    Desde_Enganche: "",
    Dato_Enganche: "",
    Porcentaje_Enganche: "* Enganche del 30% del valor del terreno.",
    Nota: {
        Valor: `Últimos cupos: <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.56868 3.57296L6.81868 2.40629H3.72526V1.23962H2.55859V12.9063H3.72526V8.23962H6.46518L8.21518 9.40629H11.8919V3.57296H8.56868Z"
          fill="${Colores_Pagina.color_principa}" 
        />
      </svg>`,
    },

    Imagenes: [
        { id: "uno", alt: "disponibilidad 1", img: Render1 },
        { id: "dos", alt: "disponibilidad 2", img: Render2 },
        { id: "tres", alt: "disponibilidad 3", img: Render3 },
    ],
}
// Presentacion Desarrollo
const Informacion_PresentacionDesarrollo = {
    Descripcion: `<b>Ciudad la Encantada</b> es un desarrollo en el que se vive un 
    entorno de armonía y seguridad, con una ubicación privilegiada en Gómez Palacio 
    y con amenidades como ciclovía, plaza central, colegio, restaurantes y una 
    infraestructura para que todos vivan sumamente cómodos.`,
    Img: DispositivosMoviles,
    Alt: "dispositivos moviles",

}
// Desarrollo
const Informacion_Desarrollo = {
    Logo: { img: Logo, alt: "logo Api" },
    Logo2: { img: propiedades_mexico, alt: "logo propiedades mexico" },
    Descripcion: `<h5><strong className="fontl">El mejor desarrollo</strong> de La
    Laguna costo-beneficio. Tranquilidad y calidad de vida en un solo lugar.<h5>`,
    Video: "https://www.youtube.com/embed/qnMW3BgMFXo"
}
// Propiedades Mexico
const Informacion_PropiedadesMexico = {
    Propiedades_Mexico: { id: "logo", alt: "logo propiedades mexico", img: LogoAPI },//propiedades_mexico },
    Propiedades_Mexico_Div: { id: "div", alt: "ciudad napi", img: imgDiv2 },
    Valores: [
        { id: 1, alt: "icono 1", icon: icon1, descripcion: "Contamos con un gran equipo de asesores", },
        { id: 2, alt: "icono 2", icon: icon2, descripcion: "Asesoría inmobiliaria profesional y calificada", },
        { id: 3, alt: "icono 3", icon: icon3, descripcion: "Oficinas en Torreón y en Ciudad La Encantada", },
        { id: 4, alt: "icono 4", icon: icon4, descripcion: "Tramitamos créditos con la tasa más baja del mercado", },
        { id: 5, alt: "icono 5", icon: icon5, descripcion: "Crédito propio", },
    ],
}
// Resultados
const Informacion_Resultados = {
    Titulo: "Licencia API en resumen",
    Valor: [
        { id: 1, alt: "propiedades vendidas", titulo: "Propiedades vendidas", valor: 0, contadorFinal: 1000, img: Propiedades },
        { id: 2, alt: "asesores", titulo: "Asesores", valor: 0, contadorFinal: 35, img: Asesores },
        { id: 3, alt: "créditos generados", titulo: "Créditos generados", valor: 0, contadorFinal: 300, img: Creditos },
        { id: 4, alt: "propiedades en lista", titulo: "Propiedades en lista", valor: 0, contadorFinal: 8000, img: PropiedadesenLista },
    ],
}


export {
    Informacion_Header,
    Informacion_Footer,
    Informacion_FormularioContacto,
    Informacion_WhatsappButton,
    Informacion_Galeria,
    Informacion_Ubicacion,
    Informacion_Amenidades,
    Informacion_Infraestructura,
    Informacion_Disponibilidad,
    Informacion_PresentacionDesarrollo,
    Informacion_Desarrollo,
    Informacion_PropiedadesMexico,
    Informacion_Resultados,

    Colores_Pagina,
};