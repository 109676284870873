import React, { useState } from "react";
import FormContacto from "../../Components/FormularioContacto/FormContacto";
import "../Contacto/Contacto.scss";
import "./Tamanosterrenos.scss";
import {
  Backgroundbigcontainer,
  Containeramenidades,
  Littleline,
  Montserrat,
} from "../../Components/Styled";
import { Grid } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Roboto } from "../../Components/Styled2";
import { Informacion_Disponibilidad } from '../../utils'

const Tamanosterrenos = ({ disponibilidad }) => {

  const { Imagenes } = Informacion_Disponibilidad;

  const [showPopup, setShowPopup] = useState(false);

  function togglePopup() {
    setShowPopup(!showPopup);
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section ref={disponibilidad} className="Section-Infraestructura">
      <Backgroundbigcontainer
        className="Tamanos-Terrenos"
        varpadding="77px 0 118px"
        varpaddingm="50px 0 70px"
        varbackgroundcolor="#F5F5F5"
      >
        <Containeramenidades>
          <Littleline />
          <Montserrat
            varweight="600"
            fontsize="48px"
            fontsizem="22px"
            varmargin="20px auto"
          >
            Disponibilidad
          </Montserrat>

          <Grid
            className="tamanos-container"
            container
            spacing={2}
            style={{ gridColumnGap: "0px" }}
          >
            <Grid className="tamanos-item " item md={4} sm={4} xs={12}>
            <div className="tablas">
                <table className="mi-tabla">
                  <tbody>
                    <tr>
                      <td colspan="2" className="celdas_negras" style={{ width: '100%', textAlign: 'center', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                        <span>FASE 1</span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan='2' style={{ width: '100%', textAlign: 'center', height: '10px'}} className="celdas_B_fases">
                        <span>Precio m²: <span style={{ fontWeight: '650' }}>$3,750.00</span></span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan='2' style={{ width: '100%', textAlign: 'center', height: '10px'}} className="celdas_B_fases">
                        <span>Precio m²: <span style={{ fontWeight: '650' }}>$3,550.00</span> <br />
                          <span className="texto_pequeno">(PRONTA CONSTRUCCIÓN / CONTADO)</span>
                        </span>
                      </td>
                    </tr>
                    <tr className="celdas_rojas">
                      <td>
                        <span>LOTES</span>
                      </td>
                      <td>
                        <span>160 m²</span>
                      </td>
                    </tr>
                    <tr className="celdas_grises">
                      <td style={{ fontWeight: "bold" }}>
                        <span>APARTADO</span>
                      </td>
                      <td>
                        <span>$7,000.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="celdas_rojas">
                        <span>VALOR TOTAL</span>
                      </td>
                      <td style={{borderBottom: '2px solid #cb100b', borderRight: '1px solid #cb100b'}}>
                        <span>$600,000.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="celdas_rojas">
                        <span>ANTICIPO 1 (15%)</span>
                      </td>
                      <td style={{borderBottom: '2px solid #cb100b', borderRight: '1px solid #cb100b'}}>
                        <span>$90,000.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="celdas_rojas">
                        <span>ANTICIPO 2 (15%)</span>
                      </td>
                      <td style={{borderBottom: '2px solid #cb100b', borderRight: '1px solid #cb100b'}}>
                        <span>$90,000.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="celdas_rojas">
                        <div>
                          <span>RESTANTE</span>
                        </div>
                      </td>
                      <td style={{borderBottom: '2px solid #cb100b', borderRight: '1px solid #cb100b'}}>
                        <span>$420,000.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="celdas_rojas">
                        <span>MENSUALIDAD</span>
                      </td>
                      <td style={{borderBottom: '1px solid #cb100b', borderRight: '1px solid #cb100b'}}>
                        <span>$11,666.67</span>
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* <table className="mi-tabla_2">
                  <tbody>
                    <tr>
                      <td colspan="2" className="celdas_negras" style={{ width: '100%', textAlign: 'center', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
                        <span>FASE 2</span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan='2' style={{ width: '100%', textAlign: 'center', height: '10px' }} className="celdas_B_fases">
                        <span>Precio m²: <strong>$3,300</strong></span>
                      </td>
                    </tr>
                    <tr>
                      <td colspan='2' style={{ width: '100%', textAlign: 'center', height: '10px'}} className="celdas_B_fases">
                        <span>Precio m²: <strong>$3,150</strong><br/>
                          <span className="texto_pequeno">(PRONTA CONSTRUCCIÓN / CONTADO)</span>
                        </span>
                      </td>
                    </tr>
                    <tr className="celdas_rojas">
                      <td>
                        <span>LOTES</span>
                      </td>
                      <td>
                        <span>200 m²</span>
                      </td>
                      <td>
                        <span>250 m²</span>
                      </td>
                    </tr>
                    <tr className="celdas_grises">
                      <td style={{ fontWeight: "bold" }}>
                        <span>APARTADO</span>
                      </td>
                      <td>
                        <span>$10,000.00</span>
                      </td>
                      <td>
                        <span>$15,000.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="celdas_rojas">
                        <span>VALOR TOTAL</span>
                      </td>
                      <td style={{borderBottom: '2px solid #cb100b'}}>
                        <span>$660,000.00</span>
                      </td>
                      <td style={{borderBottom: '1px solid #cb100b', borderLeft: '2px solid #cb100b', borderRight: '1px solid #cb100b'}}>
                        <span>$825,000.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="celdas_rojas">
                        <span>ANTICIPO 1 (15%)</span>
                      </td>
                      <td style={{borderBottom: '2px solid #cb100b'}}>
                        <span>$99,000.00</span>
                      </td>
                      <td style={{borderBottom: '1px solid #cb100b', borderLeft: '2px solid #cb100b', borderRight: '1px solid #cb100b', borderTop: '2px solid #cb100b'}}>
                        <span>$123,750.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="celdas_rojas">
                        <span>ANTICIPO 2 (15%)</span>
                      </td>
                      <td style={{borderBottom: '2px solid #cb100b'}}>
                        <span>$99,000.00</span>
                      </td>
                      <td style={{borderBottom: '1px solid #cb100b', borderLeft: '2px solid #cb100b', borderRight: '1px solid #cb100b', borderTop: '2px solid #cb100b'}}>
                        <span>$123,750.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="celdas_rojas">
                        <div>
                          <span>RESTANTE</span>
                        </div>
                      </td>
                      <td style={{borderBottom: '2px solid #cb100b'}}>
                        <span>$462,000.00</span>
                      </td>
                      <td style={{borderBottom: '1px solid #cb100b', borderLeft: '2px solid #cb100b', borderRight: '1px solid #cb100b', borderTop: '2px solid #cb100b'}}>
                        <span>$577,500.00</span>
                      </td>
                    </tr>
                    <tr>
                      <td className="celdas_rojas">
                        <span>MENSUALIDAD</span>
                      </td>
                      <td style={{borderBottom: '1px solid #cb100b'}}>
                        <span>$12,833.33</span>
                      </td>
                      <td style={{borderBottom: '1px solid #cb100b', borderLeft: '2px solid #cb100b', borderRight: '1px solid #cb100b', borderTop: '2px solid #cb100b'}}>
                        <span>$16,041.67</span>
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </div>
              <div style={{width: '100%', marginTop: '10px', textAlign: 'center'}}>
                  <span style={{color: '#cb100b', fontWeight: '600'}}>PROMOCIÓN: Pagar anticipo (30%) en 2 partes.**</span><br/>
                  <span style={{fontWeight: '600'}}>-15% de anticipo a la firma y segundo 15% a más tardar el 15 de diciembre de 2024.**</span><br/>
                  {/* <span style={{fontWeight: '600'}}>-La 2da fase será etregada en 18 meses.**</span> */}
              </div>            </Grid>
            <Grid className="tamanos-item tres" item md={8} sm={8} xs={12}>
              <Slider {...settings}>
                {Imagenes.map((imgs) => (
                  <div key={imgs.id} className={`backgrounds-containers imagen-${imgs.id}`}>
                    <img className="backgrounds-containers" src={imgs.img} alt={imgs.alt} />
                  </div>
                ))}
              </Slider>
            </Grid>
          </Grid>
          <button className="button-contacto derecha" onClick={togglePopup}>
            Contactar un asesor express
          </button>
          {showPopup && <FormContacto closePopup={togglePopup} />}
        </Containeramenidades>
      </Backgroundbigcontainer>
    </section>
  );
};

export default Tamanosterrenos;
