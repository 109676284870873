import { Informacion_WhatsappButton } from '../utils'
import React from "react";
import { BtnWhatsApp } from "./Styled2";
import { isMobile } from 'react-device-detect';


const WhatsappButton = () => {
  const { Telefono, Logo, Info } = Informacion_WhatsappButton;
  return (
    <BtnWhatsApp>
      <a href={`https://${isMobile ? "api" : "web"}.whatsapp.com/send?phone=${Telefono}&text=Hola buen día, estoy interesado en recibir información de ${Info}, mi Nombre es: `}>
        <img alt={Logo.alt} src={Logo.img} />
      </a>
    </BtnWhatsApp>
  );
};

export default WhatsappButton;
