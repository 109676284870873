import { Informacion_Desarrollo } from '../../utils'
import React, { useState } from "react";
import FormContacto from "../../Components/FormularioContacto/FormContacto";
import "../Contacto/Contacto.scss";
import { Littleline } from "../../Components/Styled";
import {
  ConteinerCLEdesarrollo,
  Columnas,
  Columna,
  Roboto,
} from "../../Components/Styled2";
import "./CLEdesarrollo.scss";

const CLEdesarrollo = () => {

  const { Descripcion, Logo, Video } = Informacion_Desarrollo;

  const [showPopup, setShowPopup] = useState(false);

  function togglePopup() {
    setShowPopup(!showPopup);
  }
  return (
    <ConteinerCLEdesarrollo className="Section-cledesarrollo">
      <Columnas varalignitems="center">
        <Columna className="cle-desarrollo">
          <div className="divlogo">
            <img className='logo-img' src={Logo.img} alt={Logo.alt} ></img>
          </div>
          <div className="divcolumn">
            <Littleline
              backgroundcolor="white"
              varmaxwidth="60px"
              className="displaynone"
            />
            <div dangerouslySetInnerHTML={{ __html: Descripcion }}></div>
            <Roboto varcolor="#ffffff" fontsize="24px">
              Llena el siguiente formulario para
            </Roboto>

            <button
              className="button-contacto desarrollobtn"
              onClick={togglePopup}
            >
              Contactar un asesor
            </button>
            {showPopup && <FormContacto closePopup={togglePopup} />}
          </div>
        </Columna>
        <Columna>
          <iframe
            width="100%"
            className="video"
            src={Video}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </Columna>
      </Columnas>
    </ConteinerCLEdesarrollo>
  );
};

export default CLEdesarrollo;
