import React from "react";
import { Informacion_Ubicacion } from '../../utils'
import { Littleline } from "../../Components/Styled";
import {
  Containerubicacion,
  Columnas,
  Columna,
  Roboto,
  Title,
} from "../../Components/Styled2";
import "./Ubicacion.scss";

const Ubicacion = ({ ubicacion }) => {
  const { UrlMapa, SubTitulo, Valores } = Informacion_Ubicacion;
  return (
    <div ref={ubicacion} className="Section-ubicacion">
      <Containerubicacion>
        <Columnas varalignitems="center" className="gridgap">
          <Columna className="background-mapa">
            <iframe
              src={UrlMapa}
              className="mapa"
              title="Ciudad La Encantada"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </Columna>
          <Columna>
            <div className="contubicacion">
              <Littleline
                varmaxwidth="60px"
                varminheight="5px"
                className="displaynone"
              />

              <Title varpaddi="20px 0 20px">Ubicación</Title>
              <Roboto>
                {SubTitulo}
              </Roboto>
              <ul>
                {Valores.map((valores) => (
                  <li key={valores.id}>
                    <Roboto>{valores.texto}</Roboto>
                  </li>
                ))}
              </ul>
            </div>
          </Columna>
        </Columnas>
      </Containerubicacion>
    </div>
  );
};

export default Ubicacion;
