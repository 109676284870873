import { Informacion_PropiedadesMexico } from '../../utils';
import React from "react";
import "./Propiedadesmexico.scss";
import { Roboto } from "../../Components/Styled2";
import { Backgroundbigcontainer } from "../../Components/Styled";

const Propiedadesmexico = ({ propiedades }) => {

  const { Propiedades_Mexico, Propiedades_Mexico_Div, Valores } = Informacion_PropiedadesMexico;

  return (
    <Backgroundbigcontainer
      varbackgroundcolor="#F5F5F5"
      varpadding="100px 0 100px"
      ref={propiedades}
    >
      <section className="Section-Propiedadesmexico" >
        <div id="sizeContent2">
          <img
            src={Propiedades_Mexico.img}
            id="sizeimage"
            alt={Propiedades_Mexico.alt}
            className="padd"
          />
          <div>
            {Valores.map((valor) => (
              <div key={valor.id} id="contenedor">
                <div className="sizecolumn">
                  <img
                    src={valor.icon}
                    id="sizeicon"
                    alt={valor.alt}
                  ></img>
                </div>
                <div>
                  <Roboto varcolor="#000000">{valor.descripcion}</Roboto>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div id="sizeContent">
          <img src={Propiedades_Mexico_Div.img} id="sizeimage2" alt={Propiedades_Mexico_Div.alt}></img>
        </div>
      </section>
    </Backgroundbigcontainer>
  );
};

export default Propiedadesmexico;
