import { Informacion_Infraestructura } from '../../utils/Constant'

import React from "react";
import { Backgroundbigcontainer, Littleline } from "../../Components/Styled";
import {
  Containerinfraestructura,
  Columnas,
  Columna,
  ColumnImage,
  Roboto,
  ColumnsImage,
  Title,
} from "../../Components/Styled2";
import "./Infraestructura.scss";

const Infraestructura = ({ infraestructura }) => {
  const { Beneficios, Imagenes, SubTitulo } = Informacion_Infraestructura;

  return (
    <section ref={infraestructura} className="Section-Infraestructura">
      <Backgroundbigcontainer varbackgroundcolor="none">
        <Containerinfraestructura>
          <Columnas
            vargridgap="50px"
            vargridcolumns="52% 43%"
            varalignitems="center"
            vargrid="none"
            className="gridgapcolumns1"
          >
            <Columna>
              <ColumnsImage
                vardisplay="innerit"
                vardirection="innerit"
                varminheight="238px"
                className="gridgapcolumns2"
              >
                {Imagenes.map((imgs) => (
                  <ColumnImage key={imgs.id} >
                    <img
                      src={imgs.imagen}
                      id="image"
                      alt={imgs.alt}
                      className={imgs.class}
                    ></img>
                  </ColumnImage>
                ))}

              </ColumnsImage>
            </Columna>
            <Columna>
              <div className="title">
                <Littleline></Littleline>
                <Title varpaddi="20px 0 20px">Infraestructura</Title>
                <h5 className="padd">
                  {SubTitulo}
                </h5>
              </div>
              <div className="beneficios">
                <Columnas
                  vargridcolumns="10% 89%"
                  vardisplay="innerit"
                  vardisplaymil="innerit"
                  vardirection="innerit"
                  className="padd gridgapcolumns3"
                >
                  {
                    Beneficios.map((beneficio) => (
                      <React.Fragment key={beneficio.id}>
                        <Columna>
                          <img
                            src={beneficio.img}
                            id="icon"
                            alt={beneficio.alt}
                          />
                        </Columna>
                        <Columna>
                          <h4>{beneficio.titulo}</h4>
                          <Roboto fontsize="18px">
                            {beneficio.subTitulo}
                          </Roboto>
                        </Columna>
                      </React.Fragment>
                    ))
                  }
                </Columnas>
              </div>
            </Columna>
          </Columnas>
        </Containerinfraestructura>
      </Backgroundbigcontainer>
    </section >
  );
};

export default Infraestructura;
