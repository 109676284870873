/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import FormContacto from "../../Components/FormularioContacto/FormContacto";
import "../Contacto/Contacto.scss";
import "./HeaderSection.scss";
import { Grid } from "@mui/material";
import { Montserrat, Roboto } from "../../Components/Styled";
import { Informacion_Header } from '../../utils'

const Header = (props) => {

  const { Titulo, SubTitulo, Caracteristicas } = Informacion_Header;

  const [showPopup, setShowPopup] = useState(false);

  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  function togglePopup() {
    setShowPopup(!showPopup);
  }

  useEffect(() => {
    function handleResize() {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      marginTopTitulo();
      calcularDistanciaYC();
      heightHeader();
    };
  }, []);

  function marginTopTitulo() {
    let distancia;
    let contenedores = Caracteristicas.length;
    if (contenedores > 0) {
      // Cantidad de contenedores *  distancia promedio

      if (size.width <= 400) {
        distancia = contenedores * 14;
      } else if (size.width <= 420) {
        distancia = contenedores * 13;
      } else if (size.width <= 599) {
        distancia = contenedores * 13;
      } else if (size.width <= 800) {
        distancia = contenedores * 7;
      } else if (size.width <= 880) {
        distancia = contenedores * 8;
      } else if (size.width <= 900) {
        distancia = contenedores * 12;
      } else if (size.width <= 920) {
        distancia = contenedores * 4;
      } else if (size.width <= 1000) {
        distancia = contenedores * 3.5;
      } else {
        let lineas = Math.ceil(contenedores / 4);
        distancia = lineas * 12;
      }
    } else {
      distancia = -20;
    }
    return distancia;
  }

  function heightHeader() {
    const sizeHeight = size.height;
    let height = 100;
    const breakpoints = [579, 479, 379, 279];

    breakpoints.forEach(element => {
      if (sizeHeight <= element) {
        height += 20;
      }
    });
    return height;
  }

  function calcularDistanciaYC() {
    let distancia = 0;
    let contenedores = Caracteristicas.length;
    if (size.width > 900) {
      // Cantidad de contenedores *  distancia promedio
      let lineas = Math.ceil(contenedores / 4);
      for (let index = 0; index < lineas; index++) {
        distancia -= 225;
      }
      return distancia;
    } else if (size.width <= 900) {
      return -275;
    }
    return null;
  }


  return (
    <div
      className="Header-Background-CLE"
      style={{
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundSize: "cover",
        marginBottom: `${marginTopTitulo()}em`,
        height: `${heightHeader()}vh`,
      }}
    >
      <Montserrat
        fontsize="72px"
        varcolor="#FFFFFF"
        varweight="700"
        varmargin="10px auto 0 5.75%"
        fontsizem="38px"
      >
        {Titulo}
      </Montserrat>
      <Roboto
        fontsize="22px"
        varwidth="30%"
        varcolor="#FFFFFF"
        varmargin="10px auto 0 5.75%"
        varalign="left"
        varlineheight="32px"
        fontsizem="16px"
        varalignm="center"
        varmarginm="20px auto 30px"
        varwidthm="80%"
      >
        {SubTitulo}
      </Roboto>

      <button className="button-contacto headerbtn" onClick={togglePopup}>
        CONTÁCTANOS
      </button>
      {showPopup && <FormContacto closePopup={togglePopup} />}
      <Grid
        className="Bottoom-box-caracteristicas"
        container
        columnSpacing={{ xs: 0, sm: 2, md: 3 }}
        style={{
          ...(calcularDistanciaYC() ? { bottom: `${calcularDistanciaYC()}px` } : {})
        }}
      >
        {
          Caracteristicas.map((caracteristica) => (
            <Grid
              key={caracteristica.id}
              item xs={12} sm={6} md={3}
              className="grid-item-card"
            >
              <img
                alt={caracteristica.alt}
                src={caracteristica.Img}
              />
              <Montserrat
                varweight="600"
                fontsize="28px"
                varcolor="#ffffff"
                varmaxwidth="95%"
              >
                {caracteristica.Titulo}
              </Montserrat>
              <Montserrat
                fontsize="22px"
                varcolor="#ffffff"
                varmaxwidth="95%"
                varmargin="15px auto 15px"
              >
                {caracteristica.Valor}
              </Montserrat>
            </Grid>
          ))
        }
      </Grid>
    </div>
  );
};

export default Header;
